import request from '@/utils/request'

// 合计
export const listTotalApi = (url, params) => {
  return request({
    url,
    method: 'get',
    params
  })
}

//列表
export const listApi = (url, params) => {
  return request({
    url,
    method: 'get',
    params
  })
}