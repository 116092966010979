import request from '@/utils/request'

//获取捆绑的商品
export function getGoodsCombo (params) {
  return request({
    url: '/api/system/goods/combo/getGoodsCombo',
    method: 'get',
    params
  })
}

//保存捆绑的商品
export function saveGoodsComboDetail (data) {
  return request({
    url: '/api/system/goods/combo/saveGoodsComboDetail',
    method: 'post',
    data
  })
}
