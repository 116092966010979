import request from '@/utils/request'

const urlPrefix = ''
const urlAddress = {
  checkList: urlPrefix + '/api/system/store/check/list', //仓库盘点单
  outList: urlPrefix + '/api/system/store/bill/out/list', //仓库调出
  listGoodStatisticsReport:
    urlPrefix + '/api/system/store/report/book/listGoodStatisticsReport', //商品出入库汇总表
  adjustList: urlPrefix + '/api/system/store/bill/adjust/list', //库存调整
  lossList: urlPrefix + '/api/system/store/bill/loss/list', //仓库报损分页列表
  allotList: urlPrefix + '/api/system/store/bill/allot/list', //仓库调拨分页列表
  storeListCheckGoods: urlPrefix + '/api/system/store/check/listCheckGoods', //仓库-选择商品
  billInList: urlPrefix + '/api/system/store/bill/in/list', //仓库调入
  differenceList: urlPrefix + '/api/system/store/bill/in/differenceList' //仓库调拨差异列表
}

export function httpCheckList (params) {
  return request({
    url: urlAddress.checkList,
    method: 'get',
    params: params
  })
}

export function httpOutList (params) {
  return request({
    url: urlAddress.outList,
    method: 'get',
    params: params
  })
}

export function httpListGoodStatisticsReport (params) {
  return request({
    url: urlAddress.listGoodStatisticsReport,
    method: 'get',
    params: params
  })
}

// 商品出入库汇总合计
export function sumListGoodStatisticsReport(params) {
  return request({
    url: "/api/system/store/report/book/sumListGoodStatisticsReport",
    method: 'get',
    params,
  })
}

export function httpAdjustList (params) {
  return request({
    url: urlAddress.adjustList,
    method: 'get',
    params: params
  })
}

export function httpLossList (params) {
  return request({
    url: urlAddress.lossList,
    method: 'get',
    params: params
  })
}

export function httpAllotList (params) {
  return request({
    url: urlAddress.allotList,
    method: 'get',
    params: params
  })
}

export function httpStoreListCheckGoods (params) {
  return request({
    url: urlAddress.storeListCheckGoods,
    method: 'get',
    params: params
  })
}

export function httpBillInList (params) {
  return request({
    url: urlAddress.billInList,
    method: 'get',
    params: params
  })
}

export function differenceList(params){
  return request({
    url: urlAddress.differenceList,
    method: 'get',
    params: params
  })
}



//仓库盘点导入
export function storeCheckInfoImport (data,billId) {
  return request({
    url: '/api/system/store/check/importStoreBillCheckDetails?billId='+billId,
    method: 'POST',
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  })
}

// 仓库调拨 复制
export function storeMoveCopy (data) {
  return request({
    url: '/api/system/store/bill/allot/copy',
    method: 'POST',
    data
  })
}

// 仓库调出单 汇总
export function sumStoreBillOut(params) {
  return request({
    url: '/api/system/store/bill/out/sumList',
    method: 'get',
    params
  })
}


// 获取商品库存
export function getGoodsStock(params) {
  return request({
    url: '/api/system/store/stock/getGoodsStock',
    method: 'get',
    params
  })
}

// 刷新账面数
export function listGoodsStock(data) {
  return request({
    url: '/api/system/store/stock/listGoodsStock',
    method: 'post',
    data
  })
}