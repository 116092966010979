import request from '@/utils/request'


//保质期预警查询表
export const getWarranty = (params) => request({
    url: '/api/system/store/reportForms/warranty',
    method: 'get',
    params
})


export function dealDueGoods(data) {
    return request({
      url: '/api/system/store/stock/dealDueGoods',
      method: 'POST',
      data
    })
  }

  //保质期预警查询表的明细
export const warrantyGoodsDueDateDetail = (params) => request({
  url: '/api/system/store/reportForms/warrantyGoodsDueDateDetail',
  method: 'get',
  params
})